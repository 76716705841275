import React, { FC, ChangeEvent, useState } from "react";
import PropTypes from "prop-types";
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Typography,
  TextField,
  CardHeader,
  Button,
} from "@mui/material";

import BulkActions from "./BulkActions";
import { useMutation, useQuery } from "@apollo/client";
import { errorToast } from "src/utils/toaster";
import SuspenseLoader from "src/components/SuspenseLoader";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { productItemsListingQuery } from "src/hook/query/item";
import { SubscriptionApproval } from "src/hook/mutations/payment";
import { usersListing } from "src/hook/query/usersLists";

interface RecentOrdersTableProps {
  className?: string;
  cryptoOrders: any;
}

interface Filters {
  status?: any;
}

const applyFilters = (cryptoOrders: any, filters: Filters): any => {
  return cryptoOrders.filter((cryptoOrder) => {
    let matches = true;

    if (filters.status && cryptoOrder.status !== filters.status) {
      matches = false;
    }

    return matches;
  });
};
const applyPagination = (data: any, page: number, limit: number): any => {
  const startIndex = page * limit;
  const endIndex = startIndex + limit;

  return data.slice(startIndex, endIndex);
};
const RecentOrdersTable: FC<RecentOrdersTableProps> = ({ cryptoOrders }) => {
  const authKey = localStorage.getItem("TOKEN");
  const [selectedCryptoOrders] = useState<string[]>(
    []
  );
  const selectedBulkActions = selectedCryptoOrders.length > 0;
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [filters] = useState<Filters>({
    status: null,
  });
  const [searchQuery, setSearchQuery] = useState<string>("");
  const {loading:fasdfsa, refetch:refetchdata}= useQuery(usersListing,{
    context:{
      headers: {
        Authorization: `Bearer ${authKey}`,
      },
    }
  })
  const [Subscriptionstatus, { loading }] = useMutation(SubscriptionApproval, {
    context: {
      headers: {
        Authorization: `Bearer ${authKey}`,
      },
    },
  });
  const { loading: deleteLoading } = useQuery(
    productItemsListingQuery,
    {
      context: {
        headers: {
          Authorization: `Bearer ${authKey}`,
        },
      },
    }
  );

  // Sorting state
  const [sorting, setSorting] = useState<any>({
    field: "name",
    order: "asc",
  });

  if (loading || deleteLoading ||fasdfsa) {
    return <SuspenseLoader />;
  }

  const handleSort = (field: string) => {
    let order;
  
    if (sorting.field === field) {
      if (sorting.order === "asc") {
        order = "desc";
      } else {
        order = "asc";
      }
    } else {
      order = "asc";
    }
  
    setSorting({ field, order });
  };
  

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setPage(0);
    setLimit(parseInt(event.target.value));
  };

  const filteredCryptoOrders = applyFilters(cryptoOrders, filters).filter(
    (cryptoOrder) => {
      const lowerSearchQuery = searchQuery.toLowerCase();
      const name = cryptoOrder?.name?.toLowerCase();
      const email = cryptoOrder?.email?.toLowerCase();
      const mobileNumber = cryptoOrder?.mobileNumber?.toLowerCase();

      return (
        name?.includes(lowerSearchQuery) ||
        email?.includes(lowerSearchQuery) ||
        mobileNumber?.includes(lowerSearchQuery)
      );
    }
  );

  const paginatedCryptoOrders = applyPagination(
    filteredCryptoOrders,
    page,
    limit
  );

  const RejectItem = async (id: string) => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Reject it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await Subscriptionstatus({
            variables: {
              userId: id,
              type:"2"
            },
          });

          if (response.data) {
            Swal.fire("Rejected!", "User has been Rejected.", "success");
            refetchdata();
          }
        }
      });
    } catch (error) {
      errorToast("Something went wrong");
    }
  };
  const ApprovedItem = async (id: string) => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, approve it",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await Subscriptionstatus({
            variables: {
              userId: id,
              type:"1"
            },
          });

          if (response.data?.subscriptionApproval) {
            Swal.fire("Approved!", "User has been Approved.", "success");
            refetchdata();
          }
          else{
            Swal.fire("Not Approved!", "User is not member.", "error");
            refetchdata();
          }
        }
      });
    } catch (error) {
      errorToast("Something went wrong");
    }
  };

  const sortedCryptoOrders = [...paginatedCryptoOrders].sort((a, b) => {
    if (sorting.order === "asc") {
      return a[sorting.field]?.localeCompare(b[sorting?.field]);
    } else {
      return b[sorting.field]?.localeCompare(a[sorting?.field]);
    }
  });

  return (
    <Card>
      {selectedBulkActions &&
        (paginatedCryptoOrders.length ? (
          <Box flex={1} p={2}>
            <BulkActions selectedValue={selectedCryptoOrders} />
          </Box>
        ) : (
          ""
        ))}
      <CardHeader
        action={
          <>
            <Box width={150}></Box>
            <TextField
              label="Search"
              variant="outlined"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </>
        }
        title="Users"
      />
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                onClick={() => handleSort("name")}
                sx={{ cursor: "pointer" }}
              >
                Name{" "}
                {sorting?.field === "name" && (
                  <span>{sorting?.order === "asc" ? " ▲" : " ▼"}</span>
                )}
              </TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Member</TableCell>
              <TableCell>Appoved</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedCryptoOrders.map((cryptoOrder) => {
              const isCryptoOrderSelected = selectedCryptoOrders.includes(
                cryptoOrder.id
              );
              return (
                <TableRow
                  hover
                  key={cryptoOrder.id}
                  selected={isCryptoOrderSelected}
                >
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      flexWrap={"wrap"}
                    
                    >
                      {(cryptoOrder?.name).charAt(0).toUpperCase() +
                        cryptoOrder?.name.slice(1)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      flexWrap={"wrap"}
                    >
                      {cryptoOrder?.email}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      flexWrap={"wrap"}
                    >
                      {cryptoOrder?.mobileNumber}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      flexWrap={"wrap"}
                    >
                      {cryptoOrder?.subscribed ? "TRUE" : "FALSE"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      flexWrap={"wrap"}
                    >
                      {cryptoOrder?.approved ? "TRUE" : "FALSE"}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Button
                      onClick={() => ApprovedItem(cryptoOrder?.id)}
                      size="small"
                      variant="contained"
                    >
                      Approve
                      {/* <EditTwoToneIcon fontSize="small" /> */}
                    </Button>
                    &nbsp;
                    <Button
                      variant="contained"
                      onClick={() => RejectItem(cryptoOrder?.id)}
                      color="error"
                      size="small"
                    >
                      Reject
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Box p={2}>
        <TablePagination
          component="div"
          count={filteredCryptoOrders.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
    </Card>
  );
};

RecentOrdersTable.propTypes = {
  cryptoOrders: PropTypes.array.isRequired,
};

RecentOrdersTable.defaultProps = {
  cryptoOrders: [],
};

export default RecentOrdersTable;
