
export const useLocalStorage = ()=>{
    const authToken = localStorage?.getItem("TOKEN");
    const loginId = JSON.parse(localStorage.getItem("USer"))?.login?.id;
    const loginName = JSON.parse(localStorage.getItem("USer"))?.login?.name;
    const loginEmail = JSON.parse(localStorage.getItem("USer"))?.login?.email;
    const loginRole = JSON.parse(localStorage.getItem("USer"))?.login?.role;
    const loginMobileNumber = JSON.parse(localStorage.getItem("USer"))?.login?.mobileNumber;
    return {
        authToken,
        loginId,
        loginName,
        loginEmail,
        loginRole,
        loginMobileNumber
    }

}