import * as React from "react";
import { useMutation } from "@apollo/client";
import { PaymentCheckout } from "src/hook/mutations/payment";
import { errorToast, successToast } from "src/utils/toaster";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/StarBorder";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { GlobalStyles } from "@mui/material";

const tiers = [
  {
    title: "Pro",
    subheader: "Most popular",
    price: "10",
    description: [
      "Help center access",
      "Priority email support",
    ],
    buttonText: "Buy Now",
    buttonVariant: "contained",
  },
];


const defaultTheme = createTheme();

export default function Pricing() {
  const authKey = localStorage.getItem("TOKEN");
  const loggedUser = JSON.parse(localStorage.getItem("USer"))?.login;
  const userId = loggedUser?.id;

  const [paymentcheckout] = useMutation(PaymentCheckout, {
    context: {
      headers: {
        Authorization: `Bearer ${authKey}`,
      },
    },
  });

  const Checkout = async () => {
    try {
      const result = await paymentcheckout({
        variables: {
          userId,
        },
      });

      if (result.data) {
        successToast("success");
        window.location.href = result?.data?.paymentCheckout;
      }
    } catch (error: any) {
      errorToast(error.message);
    }
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />

      {/* Hero unit */}
      <Container
        disableGutters
        maxWidth="sm"
        component="main"
        sx={{ pt: 8, pb: 6 }}
      >
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Pricing
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="text.secondary"
          component="p"
        >
          Supercharge your Facebook marketing with our Chrome extension – a
          powerful tool to streamline your social media efforts. 
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="center" justifyContent="center">
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={tier.title === "Enterprise" ? 12 : 6}
              md={4}
            >
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: "center" }}
                  action={tier.title === "Pro" ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: "center",
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "light"
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "baseline",
                      mb: 2,
                    }}
                  >
                    <Typography
                      component="h2"
                      variant="h3"
                      color="text.primary"
                    >
                      ${tier.price}
                    </Typography>
                    <Typography
                      variant="h6"
                      color="text.secondary"
                    ></Typography>
                  </Box>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    variant={tier.buttonVariant as "outlined" | "contained"}
                    onClick={Checkout}
                  >
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
