import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {
  Box,
  Button,
  FormGroup,
  FormLabel,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";

import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { createUserMutation } from "src/hook/query/createUser";
import { useMutation } from "@apollo/client";
import { errorToast, successToast } from "src/utils/toaster";
import { useNavigate } from "react-router";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: 60,
  lineHeight: "60px",
}));

const Registrationform = () => {
  const [userMutation] = useMutation(createUserMutation);
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const {
    values: valueData,
    errors,
    handleBlur,
    touched,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      mobileNumber: ""
    },

    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string()
        .required("Password is required")
        .min(6, "Password must be at least 6 characters"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
        mobileNumber: Yup.string()
        .matches(/^[0-9]{10}$/, "Invalid Phone Number") // You can customize the regex as needed
        .required("Phone Number is required"),
    }),
    onSubmit: async (values: any, action: any) => {
      try {
        console.log(values,"values",{
          email: values?.email,
          password: values?.password,
          name: values?.name,
        },)
        const result = await userMutation({
          variables: {
            input:{
              email: values?.email,
              password: values?.password,
              name: values?.name,
              mobileNumber: values?.mobileNumber,
              role: "2"
            }
          
          },
        });

        if (result.data) {
          successToast("User Created");
          navigate("/user/login");
          action.resetForm()
        }
      } catch (error: any) {
        if(error.message==="Email already exists"){
          errorToast("Email Already Exists");
        }else{
          errorToast("Something went wrong");
        }
        
      }
    },
  });

  const spacehandler = (event: any) => {
    if (event.which === 32 && event.target.value === "") {
      event.preventDefault();
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleShowConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  return (
    <div className="booking-main-div">
      <form action="" onSubmit={handleSubmit}>
        <Grid container className="grid-input-field">
          <Grid item xs={12} sm={12} marginBottom={3}>
            <FormGroup>
              <FormLabel sx={{ marginBottom: "3px", textAlign: "start" }}>
                Name <span className="color-danger">*</span>
              </FormLabel>
              <TextField
                id="name"
                name="name"
                placeholder=""
                fullWidth
                variant="outlined"
                onChange={handleChange}
                onKeyDown={spacehandler}
                value={valueData.name}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
                onBlur={handleBlur}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={12} marginBottom={3}>
            <FormGroup>
              <FormLabel sx={{ marginBottom: "3px", textAlign: "start" }}>
                Email Address <span className="color-danger">*</span>
              </FormLabel>
              <TextField
                id="email"
                name="email"
                placeholder=""
                fullWidth
                onChange={handleChange}
                value={valueData.email}
                variant="outlined"
                onKeyDown={spacehandler}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                onBlur={handleBlur}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={12} marginBottom={3}>
            <FormGroup>
              <FormLabel sx={{ marginBottom: "3px", textAlign: "start" }}>
                Phone Number <span className="color-danger">*</span>
              </FormLabel>
              <TextField
                id="mobileNumber"
                name="mobileNumber"
                placeholder=""
                fullWidth
                onChange={handleChange}
                value={valueData.mobileNumber}
                variant="outlined"
                onKeyDown={spacehandler}
                error={touched.mobileNumber && Boolean(errors.mobileNumber)}
                helperText={touched.mobileNumber && errors.mobileNumber}
                onBlur={handleBlur}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={12} marginBottom={3}>
            <FormGroup>
              <FormLabel sx={{ marginBottom: "3px", textAlign: "start" }}>
                Password <span className="color-danger">*</span>
              </FormLabel>
              <TextField
                id="password"
                name="password"
                placeholder=""
                fullWidth
                type={showPassword ? "text" : "password"}
                variant="outlined"
                onChange={handleChange}
                onKeyDown={spacehandler}
                value={valueData.password}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                onBlur={handleBlur}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={togglePasswordVisibility} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={12} marginBottom={3}>
            <FormGroup>
              <FormLabel sx={{ marginBottom: "3px", textAlign: "start" }}>
                Confirm Password <span className="color-danger">*</span>
              </FormLabel>
              <TextField
                id="confirmPassword"
                name="confirmPassword"
                placeholder=""
                fullWidth
                type={showConfirmPassword ? "text" : "password"}
                variant="outlined"
                onChange={handleChange}
                onKeyDown={spacehandler}
                value={valueData.confirmPassword}
                error={
                  touched.confirmPassword && Boolean(errors.confirmPassword)
                }
                helperText={touched.confirmPassword && errors.confirmPassword}
                onBlur={handleBlur}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={toggleShowConfirmPasswordVisibility}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Box sx={{ mb: "10px" }}>
          <Button
            variant="contained"
            className="mb-5"
            type="submit"
            sx={{ width: "300px", color: "white" }}
          >
            Submit
          </Button>
        </Box>
      </form>
    </div>
  );
};

export default Registrationform;
