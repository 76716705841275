// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
    font-family: sans-serif;
    text-align: center;
  }
  .circle {
    stroke-dasharray: 1194;
    stroke-dashoffset: 1194;
    animation: dc 1s ease-in-out;
    animation-fill-mode: forwards;
  }
  .tick {
    stroke-dasharray: 350;
    stroke-dashoffset: 350;
    animation: dt 0.8s ease-out;
    animation-fill-mode: forwards;
    animation-delay: 0.95s;
  }
  @keyframes dc {
    from {
      stroke-dashoffset: 1194;
    }
    to {
      stroke-dashoffset: 2388;
    }
  }
  @keyframes dt {
    from {
      stroke-dashoffset: 350;
    }
    to {
      stroke-dashoffset: 0;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/checkout/checkout.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,kBAAkB;EACpB;EACA;IACE,sBAAsB;IACtB,uBAAuB;IACvB,4BAA4B;IAC5B,6BAA6B;EAC/B;EACA;IACE,qBAAqB;IACrB,sBAAsB;IACtB,2BAA2B;IAC3B,6BAA6B;IAC7B,sBAAsB;EACxB;EACA;IACE;MACE,uBAAuB;IACzB;IACA;MACE,uBAAuB;IACzB;EACF;EACA;IACE;MACE,sBAAsB;IACxB;IACA;MACE,oBAAoB;IACtB;EACF","sourcesContent":[".App {\n    font-family: sans-serif;\n    text-align: center;\n  }\n  .circle {\n    stroke-dasharray: 1194;\n    stroke-dashoffset: 1194;\n    animation: dc 1s ease-in-out;\n    animation-fill-mode: forwards;\n  }\n  .tick {\n    stroke-dasharray: 350;\n    stroke-dashoffset: 350;\n    animation: dt 0.8s ease-out;\n    animation-fill-mode: forwards;\n    animation-delay: 0.95s;\n  }\n  @keyframes dc {\n    from {\n      stroke-dashoffset: 1194;\n    }\n    to {\n      stroke-dashoffset: 2388;\n    }\n  }\n  @keyframes dt {\n    from {\n      stroke-dashoffset: 350;\n    }\n    to {\n      stroke-dashoffset: 0;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
