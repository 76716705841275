import React, { FC, ChangeEvent, useState } from "react";
import PropTypes from "prop-types";
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Typography,
  TextField,
  CardHeader,
} from "@mui/material";

import BulkActions from "./BulkActions";
import {  useQuery } from "@apollo/client";
import SuspenseLoader from "src/components/SuspenseLoader";
import "sweetalert2/src/sweetalert2.scss";
import { productItemsListingQuery } from "src/hook/query/item";
import { usersListing } from "src/hook/query/usersLists";

interface RecentOrdersTableProps {
  className?: string;
  cryptoOrders: any;
}

interface Filters {
  status?: any;
}

const applyFilters = (cryptoOrders: any, filters: Filters): any => {
  return cryptoOrders.filter((cryptoOrder) => {
    let matches = true;

    if (filters.status && cryptoOrder.status !== filters.status) {
      matches = false;
    }

    return matches;
  });
};
const applyPagination = (data: any, page: number, limit: number): any => {
  const startIndex = page * limit;
  const endIndex = startIndex + limit;

  return data.slice(startIndex, endIndex);
};
const RecentOrdersTable: FC<RecentOrdersTableProps> = ({ cryptoOrders }) => {
  const authKey = localStorage.getItem("TOKEN");
  const [selectedCryptoOrders] = useState<string[]>(
    []
  );
  const selectedBulkActions = selectedCryptoOrders.length > 0;
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [filters] = useState<Filters>({
    status: null,
  });
  const [searchQuery, setSearchQuery] = useState<string>("");
  const {loading:fasdfsa}= useQuery(usersListing,{
    context:{
      headers: {
        Authorization: `Bearer ${authKey}`,
      },
    }
  })

  const { loading: deleteLoading } = useQuery(
    productItemsListingQuery,
    {
      context: {
        headers: {
          Authorization: `Bearer ${authKey}`,
        },
      },
    }
  );

  // Sorting state
  const [sorting, setSorting] = useState<any>({
    field: "name",
    order: "asc",
  });

 

  const handleSort = (field: string) => {
    let order:any;
  
    if (sorting?.field === field) {
      if (sorting?.order === "asc") {
        order = "desc";
      } else {
        order = "asc";
      }
    } else {
      order = "asc";
    }
  
    setSorting({ field, order });
  };
  

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setPage(0);
    setLimit(parseInt(event.target.value));
  };

  const filteredCryptoOrders = applyFilters(cryptoOrders, filters).filter(
    (cryptoOrder:any) => {
      const lowerSearchQuery = searchQuery.toLowerCase();
      const name = cryptoOrder?.userId.name?.toLowerCase();
      const email = cryptoOrder?.userId?.email?.toLowerCase();
      const mobileNumber = cryptoOrder?.userId?.mobileNumber?.toLowerCase();

      return (
        name?.includes(lowerSearchQuery) ||
        email?.includes(lowerSearchQuery) ||
        mobileNumber?.includes(lowerSearchQuery)
      );
    }
  );

  const paginatedCryptoOrders = applyPagination(
    filteredCryptoOrders,
    page,
    limit
  );
   
    console.log(paginatedCryptoOrders,"paginaton")
    const sortedCryptoOrders = [...paginatedCryptoOrders].sort((a, b) => {
      const nameA = a.userId?.name || ''; // Default to empty string if name is undefined
      const nameB = b.userId?.name || ''; // Default to empty string if name is undefined
    
      if (sorting.order === 'asc') {
        return nameA.localeCompare(nameB);
      } else {
        return nameB.localeCompare(nameA);
      }
    });
    
  console.log(sortedCryptoOrders,"so")
  if ( deleteLoading ||fasdfsa) {
    return <SuspenseLoader />;
  }
  return (
    <Card>
      {selectedBulkActions &&
        (paginatedCryptoOrders.length ? (
          <Box flex={1} p={2}>
            <BulkActions selectedValue={selectedCryptoOrders} />
          </Box>
        ) : (
          ""
        ))}
      <CardHeader
        action={
          <>
            <Box width={150}></Box>
            <TextField
              label="Search"
              variant="outlined"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </>
        }
        title="Payment Transactions"
      />
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                onClick={() => handleSort("name")}
                sx={{ cursor: "pointer" }}
              >
                Name
                {sorting?.field === "name" && (
                  <span>{sorting?.order === "asc" ? " ▲" : " ▼"}</span>
                )}
              </TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone Number</TableCell>
            
              <TableCell>Is Member</TableCell>
              <TableCell>Billing Date</TableCell>
              <TableCell>($) Amount</TableCell>
            
            </TableRow>
          </TableHead>
          <TableBody>
            {
             
            sortedCryptoOrders.map((cryptoOrder) => {
       
              const isCryptoOrderSelected = selectedCryptoOrders.includes(
                cryptoOrder.id
              );
              return (
                <TableRow
                  hover
                  key={cryptoOrder.id}
                  selected={isCryptoOrderSelected}
                >
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      flexWrap={"wrap"}
                    
                    >
                      {(cryptoOrder?.userId?.name).charAt(0).toUpperCase() +
                        cryptoOrder?.userId?.name.slice(1)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      flexWrap={"wrap"}
                    >
                      {cryptoOrder?.userId?.email}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      flexWrap={"wrap"}
                    >
                      {cryptoOrder?.userId?.mobileNumber}
                    </Typography>
                  </TableCell>
                 
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      flexWrap={"wrap"}
                    >
                      {cryptoOrder?.userId?.subscribed ? "TRUE" : "FALSE"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      flexWrap={"wrap"}
                    >
                      {cryptoOrder && new Date(cryptoOrder?.billingDate).toLocaleDateString() }
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      flexWrap={"wrap"}
                    >
                      {cryptoOrder?.amount }
                    </Typography>
                  </TableCell>

                 
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Box p={2}>
        <TablePagination
          component="div"
          count={filteredCryptoOrders.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
    </Card>
  );
};

RecentOrdersTable.propTypes = {
  cryptoOrders: PropTypes.array.isRequired,
};

RecentOrdersTable.defaultProps = {
  cryptoOrders: [],
};

export default RecentOrdersTable;
