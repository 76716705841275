import React from "react";
import { Helmet } from "react-helmet-async";
import PageTitle from "src/components/PageTitle";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import ProductItemListing from "./Transactions";

const ManageTransaction = () => {
  return (
    <>
      <Helmet>
        <title>Manage Transaction</title>
      </Helmet>
      <PageTitleWrapper>
        <PageTitle
          heading="Manage Transaction"
        />
      </PageTitleWrapper>
      <ProductItemListing />
    </>
  );
};

export default ManageTransaction;