
import { gql } from '@apollo/client';

export const userloginQuery = gql`
query Login($input: CreateUserInput) {
  login(input: $input) {
    id
    email
    password
    mobileNumber
    isDeleted
    name
    token
    role
  }
}
`;

export const verifyToken=gql`
query VerifyToken($token: String!) {
  verifyToken(token: $token) {
    oid
    loginId
    email
    iat
    exp
  }
}`


