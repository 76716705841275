// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiBox-root.css-13o7eu2 {
  margin-top: -79px !important;
}

@media (max-width: 1279px) {
  .MuiBox-root.css-13o7eu2 {
    margin-top: 0px !important;
    
  }
  /* textarea#mui-2,
  textarea#mui-1 {
    margin-top: 20px !important;
  } */
}
@media (min-width: 204px) and (max-width: 1200px) {
  .MuiBox-root.css-1y916ii {
    display: flex !important;
  }
  button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textSecondary.MuiButton-sizeMedium.MuiButton-textSizeMedium.css-1uoat66-MuiButtonBase-root-MuiButton-root {
    display: flex !important;
  }
}
/* form errors */

.form-error {
  padding-left: 10px !important;
  color: red !important;
  font-size: 13px !important;
}

td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.css-d4nlz6-MuiTableCell-root {
    word-break: break-all;
}


.css-stelg4-MuiPaper-root-MuiCard-root{
  width: 100% !important;
}

@media(min-width:1280px){
.css-kjeztr-MuiGrid-root {

    max-width: 100% !important;

}
}
.css-kjeztr-MuiGrid-root {

  max-width: 100% !important;

}
.css-z57lvz-MuiGrid-root{
  margin-left: 0 !important;
}
.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-3.css-z57lvz-MuiGrid-root {
  display: block;
  width: 100%;
}
.css-z57lvz-MuiGrid-root{
  margin-left: 0 !important;
}
.css-z57lvz-MuiGrid-root>.MuiGrid-item{
  padding-left: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/Asset/globle css/globle.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B;;AAEA;EACE;IACE,0BAA0B;;EAE5B;EACA;;;KAGG;AACL;AACA;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,wBAAwB;EAC1B;AACF;AACA,gBAAgB;;AAEhB;EACE,6BAA6B;EAC7B,qBAAqB;EACrB,0BAA0B;AAC5B;;AAEA;IACI,qBAAqB;AACzB;;;AAGA;EACE,sBAAsB;AACxB;;AAEA;AACA;;IAEI,0BAA0B;;AAE9B;AACA;AACA;;EAEE,0BAA0B;;AAE5B;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,cAAc;EACd,WAAW;AACb;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,0BAA0B;AAC5B","sourcesContent":[".MuiBox-root.css-13o7eu2 {\n  margin-top: -79px !important;\n}\n\n@media (max-width: 1279px) {\n  .MuiBox-root.css-13o7eu2 {\n    margin-top: 0px !important;\n    \n  }\n  /* textarea#mui-2,\n  textarea#mui-1 {\n    margin-top: 20px !important;\n  } */\n}\n@media (min-width: 204px) and (max-width: 1200px) {\n  .MuiBox-root.css-1y916ii {\n    display: flex !important;\n  }\n  button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textSecondary.MuiButton-sizeMedium.MuiButton-textSizeMedium.css-1uoat66-MuiButtonBase-root-MuiButton-root {\n    display: flex !important;\n  }\n}\n/* form errors */\n\n.form-error {\n  padding-left: 10px !important;\n  color: red !important;\n  font-size: 13px !important;\n}\n\ntd.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.css-d4nlz6-MuiTableCell-root {\n    word-break: break-all;\n}\n\n\n.css-stelg4-MuiPaper-root-MuiCard-root{\n  width: 100% !important;\n}\n\n@media(min-width:1280px){\n.css-kjeztr-MuiGrid-root {\n\n    max-width: 100% !important;\n\n}\n}\n.css-kjeztr-MuiGrid-root {\n\n  max-width: 100% !important;\n\n}\n.css-z57lvz-MuiGrid-root{\n  margin-left: 0 !important;\n}\n.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-3.css-z57lvz-MuiGrid-root {\n  display: block;\n  width: 100%;\n}\n.css-z57lvz-MuiGrid-root{\n  margin-left: 0 !important;\n}\n.css-z57lvz-MuiGrid-root>.MuiGrid-item{\n  padding-left: 0 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
