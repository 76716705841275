import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {
  Box,
  Button,
  FormGroup,
  FormLabel,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";

import { createTheme, styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useLazyQuery, useQuery } from "@apollo/client";
import { userloginQuery } from "src/hook/query/login";
import { errorToast, successToast } from "src/utils/toaster";
import { useNavigate } from "react-router";
import SuspenseLoader from "src/components/SuspenseLoader";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: 60,
  lineHeight: "60px",
}));

const AddUserLogin = () => {
    const navigate= useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [login, { loading }] = useLazyQuery(userloginQuery, {
    onCompleted: (data) => {
      localStorage.setItem("TOKEN", data?.login?.token?.token);
      localStorage.setItem("USERNAME",JSON.stringify({firstName: data?.login?.info?.firstName, lastName: data?.login?.info?.lastName}))
      successToast("Login Successful");
      navigate("/");
    },
  });

  const {
    values: valueData,
    errors,
    handleBlur,
    touched,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },

    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string()
        .required("Password is required")
        .min(6, "Password must be at least 6 characters"),
    }),
    onSubmit: async (values: any, action: any) => {
            try {
                const result = await login({
                    variables: {input:{
                      email: values?.email,
                      password: values?.password,
                    }}
                  });
                  if (!result.data) {
                  errorToast("Email or Password Not Matched");
                  return
                  }
                  localStorage.setItem("TOKEN", result?.data?.login?.token)
                  localStorage.setItem("USer", JSON.stringify(result?.data))
                  action.resetForm();
                  if(result.data?.login?.role=="1"){
                    navigate("/admin/manageusers")
                  }
                  else if(result.data?.login?.role=="2") {
                    navigate("/user/profile")
                  }
                
            } catch (error:any) {
                errorToast(error.message);
            }
    },
  });

  const spacehandler = (event: any) => {
    if (event.which === 32 && event.target.value === "") {
      event.preventDefault();
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="booking-main-div">
      <form action="" onSubmit={handleSubmit}>
        <Grid container className="grid-input-field">
          <Grid item xs={12} sm={12} marginBottom={3}>
            <FormGroup>
              <FormLabel sx={{ marginBottom: "3px", textAlign: "start" }}>
                Email Address <span className="color-danger">*</span>
              </FormLabel>
              <TextField
                id="email"
                name="email"
                placeholder=""
                fullWidth
                onChange={handleChange}
                value={valueData.email}
                variant="outlined"
                onKeyDown={spacehandler}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                onBlur={handleBlur}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={12} marginBottom={3}>
            <FormGroup>
              <FormLabel sx={{ marginBottom: "3px", textAlign: "start" }}>
                Password <span className="color-danger">*</span>
              </FormLabel>
              <TextField
                id="password"
                name="password"
                placeholder=""
                fullWidth
                type={showPassword ? "text" : "password"}
                variant="outlined"
                onChange={handleChange}
                onKeyDown={spacehandler}
                value={valueData.password}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                onBlur={handleBlur}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={togglePasswordVisibility} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Box sx={{ mb: "10px" }}>
          <Button
            variant="contained"
            className="mb-5"
            type="submit"
            sx={{ width: "300px", color: "white" }}
          >
            Login
          </Button>
        
        </Box>
     
      </form>
    </div>
  );
};

export default AddUserLogin;
