
import { gql } from '@apollo/client';

export const createUserMutation = gql`
mutation CreateUser($input: CreateUser) {
  createUser(input: $input) {
    id
    email
    password
    isDeleted
    name
    mobileNumber
    role
  }
}
`;

export const forgetPasswordMutation =gql`
query Query($email: String!) {
  sendMailForForgetPassword(email: $email)
}

`
export const ChangeResetPassword = gql`
mutation ChangePassword($password: String!, $email: String!) {
  changePassword(password: $password, email: $email) {
    id
    email
    password
    isDeleted
    name
  }
}`


