import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router";
import ProtectedRoute from "./router/ProtectedRoute";
import SidebarLayout from "./layouts/SidebarLayout";
import Status404 from "./content/pages/Status/Status404";
import Status500 from "./content/pages/Status/Status500";
import ForgetPaswordOverview from "./content/pages/Components/forget-password";
import ResetPaswordOverview from "./content/pages/Components/reset-password";
import PublicRoute from "./router/publicRoute";
import UserOverview from "./components/user/overview";
import UserRegistrationOverview from "./components/user/Registration";
import UserSidebarLayout from "./layouts/UserSideBarLayout";
import UserDashboard from "./components/user/dashboard/UserDashboard";
import UserProfile from "./components/user/dashboard/userProfile/UserProfile";
import BecameMember from "./components/user/Member/Pricing";
import ItemComponent from "./components/admin/Items";
import SuccessPage from "./components/checkout/SuccessPage";
import ManageExtensions from "./components/user/Transactions/ManageExtensions";
import ManageTransaction from "./components/user/Transactions/ManageTransaction";
import ManageTransactionsForAdmin from "./components/admin/manageTransaction";
import { useLocalStorage } from "./components/storage/LocalStorage";
const MainRouter = () => {
    const {loginRole}= useLocalStorage()
    console.log(loginRole,"loginRole");
    const [role, setRole]= useState<any>("")
    const pathName=useLocation();
    useEffect(()=>{
      setRole(loginRole)
    },[loginRole,role,pathName])
  return (
    <>
      <Routes>
      <Route path="" element={<UserOverview />} />
        <Route element={<PublicRoute />}>
        <Route  path="/user" >
      
          <Route path="login" element={<UserOverview />} />
          <Route path="registration" element={<UserRegistrationOverview />} />
          <Route path="forget-password" element={<ForgetPaswordOverview />} />
          <Route
            path="reset-password/:token"
            element={<ResetPaswordOverview />}
          />
        </Route>

        <Route  path="/admin" >
          <Route path="login" element={<UserOverview />} />
          <Route path="registration" element={<UserRegistrationOverview />} />
          <Route path="forget-password" element={<ForgetPaswordOverview />} />
          <Route
            path="reset-password/:token"
            element={<ResetPaswordOverview />}
          />
        </Route>

        </Route>
        <Route path="checkoutSuccesfully" element={<SuccessPage />} />
        <Route path="404" element={<Status404 />} />
        <Route path="*" element={<Status404 />} />
        <Route path="500" element={<Status500 />} />
        <Route element={<ProtectedRoute />}>
         {role==="1"? <Route  path="/admin" element={<SidebarLayout />}>
            <Route path="manageusers" element={<ItemComponent />} />
            <Route path="manageTransaction" element={<ManageTransactionsForAdmin />} />
          </Route>:

          <Route  element={<UserSidebarLayout />}>
        
            <Route path="/user" >
          <Route path="profile" element={<UserProfile />} />
          <Route path="dashboard" element={<UserDashboard />} />
          <Route path="becomeamember" element={<BecameMember />} />
          <Route path="manageextension" element={<ManageExtensions />} />
          <Route path="transaction" element={<ManageTransaction />} />
          </Route>
          </Route>}
        </Route>
      </Routes>
    </>
  );
};

export default MainRouter;
