import React from "react";
import { Outlet, Navigate } from "react-router-dom";

const ProtectedRoute = () => {
  const authKey = localStorage.getItem("TOKEN");

  if (!authKey) {
    localStorage.clear();
    return <Navigate to="/user/login" />;
  }
  return authKey ? <Outlet /> : <Navigate to="/user/login" />;
};

export default ProtectedRoute;
