import { gql } from "@apollo/client";

export const usersListing = gql`
  query GetAllUser {
    getAllUser {
      id
      email
      password
      isDeleted
      name
      mobileNumber
      subscribed
      approved
    }
  }
`;
