import React, { useEffect, useState } from 'react';
import { Box, Card, Typography } from '@mui/material';

const UserProfile = () => {
    const [loginUser, setLoginUser] = useState({ name: '', email: '' , mobileNumber:""});

    useEffect(() => {
        const loggedUser = JSON.parse(localStorage.getItem("USer"))?.login;
        setLoginUser({
            name: loggedUser?.name || '',
            email: loggedUser?.email || '',
            mobileNumber: loggedUser?.mobileNumber || ""
        });
    }, []);

    return (
        <section style={{ maxWidth: '1440px', margin: '25px' }}>
            <Card sx={{ backgroundColor: '#FFFFFF1A', borderRadius: "1px !important", boxShadow: '0px 0px 30px 0px #00000014', padding: '15px' }}>
                <Typography variant="h2" mt={2} component="h2">
                    Personal Details
                </Typography>
                <Typography variant="h3" color="#898989" fontWeight={'unset'} fontSize={16} my={2} component="p">
                    Manage information related to your personal details
                </Typography>
                <hr />
                {renderDetail("Name", loginUser?.name)}
                {renderDetail("Email", loginUser?.email)}
                {renderDetail("Phone Number", loginUser?.mobileNumber)}
            </Card>
        </section>
    );
};

const renderDetail = (label: {}, value: {}) => (
    <Box
        sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        }}
    >
        <Typography variant="h3" color="#898989" fontWeight={'unset'} fontSize={14} my={2} component="p">
            {label}: <span style={linkStyle}><b>{value}</b></span>
        </Typography>
    </Box>
);

const linkStyle = {
    color: '#223354',
    fontWeight: "500",
    fontSize: "16px",
};

export default UserProfile;
