import { gql } from "@apollo/client";

export const PaymentCheckout= gql`
mutation PaymentCheckout($userId: String!) {
    paymentCheckout(userId: $userId)
  }
`

export const SubscriptionApproval= gql`
mutation SubscriptionApproval($userId: String!, $type: String!) {
    subscriptionApproval(userId: $userId, type: $type) {
      id
      email
      password
      isDeleted
      mobileNumber
      name
      subscribed
      role
    }
  }
`
export const TransactionByUser= gql`
query Query($userId: String) {
  transactionByUser(userId: $userId)
}
`


