import React from "react";
import "./checkout.css";
import {  Button } from "@mui/material";
import { useNavigate } from "react-router";

const SuccessPage = () => {
  const navigate= useNavigate();
  return (
    <div style={{
      display:"flex",
      height:"100%",
      width:"100%",
      justifyContent:"center",
      alignItems:"center",
    }}>
      <div>
      <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // flexDirection: "column",
        }}>
        <div className="content">
          <svg width="200" height="200">
            <circle
              fill="none"
              stroke="#68E534"
              strokeWidth="10"
              cx="100"
              cy="100"
              r="90"
              strokeLinecap="round"
              transform="rotate(-90 100 100)"
              className="circle"
            />
            <polyline
              fill="none"
              stroke="#68E534"
              points="38,107 86,128 150,72"
              strokeWidth="12"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="tick"
            />
          </svg>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1>Payment Successful!</h1>
        <p>Thank you for your payment.</p>
        <Button onClick={()=>navigate("/user/profile")}>Go To Home</Button>
      </div>
      </div>
    </div>
  );
};

export default SuccessPage;
