import React, { FC, useState } from "react";
import PropTypes from "prop-types";
import {
  Divider,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Typography,
} from "@mui/material";

import {  useQuery } from "@apollo/client";
import SuspenseLoader from "src/components/SuspenseLoader";
import "sweetalert2/src/sweetalert2.scss";

import { productItemsListingQuery } from "src/hook/query/item";


interface RecentOrdersTableProps {
  className?: string;
  cryptoOrders: any;
}


const RecentOrdersTable: FC<RecentOrdersTableProps> = ({ cryptoOrders }) => {
  const authKey = localStorage.getItem("TOKEN");
  const [selectedCryptoOrders] = useState<string[]>(
    []
  );

  const { loading: deleteLoading } = useQuery(
    productItemsListingQuery,
    {
      context: {
        headers: {
          Authorization: `Bearer ${authKey}`,
        },
      },
    }
  );



  if (deleteLoading) {
    return <SuspenseLoader />;
  }


  return (
    <Card>
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name </TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Subscribed</TableCell>
              <TableCell>Transaction Date</TableCell>
              <TableCell>Appoved</TableCell>
              <TableCell>($) Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cryptoOrders?.map((cryptoOrder) => {
              const isCryptoOrderSelected = selectedCryptoOrders.includes(
                cryptoOrder.id
              );
              return (
                <TableRow
                  hover
                  key={cryptoOrder.id}
                  selected={isCryptoOrderSelected}
                >
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      flexWrap={"wrap"}
                    >
                      {(cryptoOrder?.userId?.name).charAt(0).toUpperCase() +
                        cryptoOrder?.userId?.name.slice(1)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      flexWrap={"wrap"}
                    >
                      {cryptoOrder?.userId?.email}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      flexWrap={"wrap"}
                    >
                      {cryptoOrder?.userId?.mobileNumber}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      flexWrap={"wrap"}
                    >
                      {cryptoOrder?.userId?.subscribed ? "TRUE" : "FALSE"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      flexWrap={"wrap"}
                    >
                      {cryptoOrder && new Date(cryptoOrder?.billingDate).toLocaleDateString()}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      flexWrap={"wrap"}
                    >
                      {cryptoOrder?.userId?.approved ? "TRUE" : "FALSE"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      flexWrap={"wrap"}
                    >
                      {cryptoOrder?.amount ? cryptoOrder?.amount : ""}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

RecentOrdersTable.propTypes = {
  cryptoOrders: PropTypes.array.isRequired,
};

RecentOrdersTable.defaultProps = {
  cryptoOrders: [],
};

export default RecentOrdersTable;
