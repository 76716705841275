import React, { useEffect, useState } from "react";
import PageTitle from "src/components/PageTitle";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import LockIcon from "@mui/icons-material/Lock";

import {
  Button,
  Card,
  Box,
  Grid,
  Typography,
  Divider,
  Tooltip,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { checkoutUserSubscription } from "src/hook/mutations/prakages";
import { useLocalStorage } from "src/components/storage/LocalStorage";
import SuspenseLoader from "src/components/SuspenseLoader";

function ManageExtensions() {
  const { authToken, loginId } = useLocalStorage();
  const [checkApproval, setCheckApproval] = useState<any>();
  const [loginUser, setLoginUser] = useState();
  useEffect(() => {
    const loggedUser = JSON.parse(localStorage.getItem("USer"))?.login;
    setLoginUser(loggedUser?.id);
    checkcheckoutAccess();
  }, [loginUser, checkApproval]);

  const [checkoutUserAccess, { loading }] = useMutation(
    checkoutUserSubscription,
    {
      context: {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      },
    }
  );
  const checkcheckoutAccess = async () => {
    try {
      const result = await checkoutUserAccess({
        variables: {
          userId: loginId,
        },
      });
      console.log(
        result?.data?.subscriptionCheckByUser?.approved,
        "result?.data?.subscriptionCheckByUser?.approved"
      );
      if (!result?.data?.subscriptionCheckByUser?.approved) {
        console.log(checkApproval, "checkApproval1");
        setCheckApproval(false);
      } else {
        console.log(checkApproval, "checkApproval1");
        setCheckApproval(true);
      }
    } catch (error: any) {
      setCheckApproval(false);
      throw new Error(error?.message);
    }
  };

  const myExtension=(url)=>{
    location.href=url;
  }
  if (loading) {
    return <SuspenseLoader />;
  }
  return (
    <>
      <PageTitleWrapper>
        <PageTitle heading="Manage Extension" />
      </PageTitleWrapper>
      <Card sx={{ margin: "40px" }}>
        <Grid spacing={0} container>
          <Grid item xs={12} md={6}>
            <Box p={4}>
              <Grid container spacing={3}>
                <Grid
                  sm
                  item
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "auto",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography variant="h3" m={2}>
                    Free Version Extension
                  </Typography>
                  <Button variant="contained" onClick={()=>myExtension("/extension/Free.zip")}>Download Chrome extension</Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid
            sx={{
              position: "relative",
            }}
            display="flex"
            alignItems="center"
            item
            xs={12}
            md={6}
          >
            <Box
              component="span"
              sx={{
                display: { xs: "none", md: "inline-block" },
              }}
            >
              <Divider absolute orientation="vertical" />
            </Box>
            <Box py={4} pr={4} flex={1}>
              <Grid container spacing={0}>
                <Grid
                  sm
                  item
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "auto",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography variant="h3" m={2}>
                    Paid Version Extension
                  </Typography>
                  {checkApproval ? (
                    <>
                      <Button variant="contained" onClick={()=>myExtension("/extension/Paid8523147fasdfasdf.zip")}>
                        Download Chrome extension
                      </Button>
                    </>
                  ) : (
                    <>
                      <Tooltip
                        title="This option will be available when you become a member"
                        arrow
                      >
                        <Button variant="contained" startIcon={<LockIcon />}>
                          Download Chrome extension
                        </Button>
                      </Tooltip>
                    </>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}

export default ManageExtensions;
