import { Card } from '@mui/material';
import RecentOrdersTable from './RecentOrdersTable';
import { useLazyQuery } from '@apollo/client';
import SuspenseLoader from 'src/components/SuspenseLoader';
import { TransactionByUser } from 'src/hook/mutations/payment';
import { useEffect, useState } from 'react';


function RecentOrders() {
  const authKey= localStorage.getItem("TOKEN");
  const [loginUser, setLoginUser] = useState();
  const [data,setData]= useState<any>({})
  useEffect(() => {
    const loggedUser = JSON.parse(localStorage.getItem("USer"))?.login;
    setLoginUser(loggedUser?.id);
    checkcheckoutAccess();
  }, [loginUser]);

  const [provideUserId, {loading}]= useLazyQuery(TransactionByUser,{
    context:{
      headers: {
        Authorization: `Bearer ${authKey}`,
      },
    }
  })

  const checkcheckoutAccess = async () => {
    try {
      const result = await provideUserId({
        variables: {
          userId: loginUser
        },
      });
      if (result?.data) {
        setData(result?.data)
      }
    } catch (error: any) {
      throw new Error(error.message);
    }
  };
  const cryptoOrders: any =data?.transactionByUser;
  if(loading){
    return <SuspenseLoader/>
  }
  return (
    <Card>
      <RecentOrdersTable cryptoOrders={cryptoOrders} />
    </Card>
  );
}

export default RecentOrders;

