import React from "react";
import { Helmet } from "react-helmet-async";
import PageTitle from "src/components/PageTitle";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import SuspenseLoader from "src/components/SuspenseLoader";
import { useQuery } from "@apollo/client";
import { footerQuery } from "src/hook/query/footer";
import ProductItemListing from "./Transactions";

const ManageTransactionsForAdmin = () => {
  const authKey= localStorage.getItem("TOKEN")
  const { loading } = useQuery(footerQuery,{
    context:{
      headers: {
        Authorization: `Bearer ${authKey}`,
      },
    }
  });

  if (loading) {
    return <SuspenseLoader />;
  }


  return (
    <>
      <Helmet>
        <title>Manage Transaction</title>
      </Helmet>
      <PageTitleWrapper>
        <PageTitle
          heading="Manage Transaction"
        />
      </PageTitleWrapper>
      <ProductItemListing />
    </>
  );
};

export default ManageTransactionsForAdmin;